import { Link } from 'gatsby';
import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Button, Grid, Heading, Text } from 'theme-ui';

import { CallToBook, Gander, StayTheNight } from 'components';

import { AttractionMap, LiveEvents, SEO } from 'components';

import PawIcon from 'images/svg/paw-icon.svg';
import LogoStacked from '../../static/images/svg/logo-stacked.svg';

function HomeHero() {
  return (
    <Grid
      variant="boxes.container"
      pt={['xl', null, 'xxxl']}
      pb={['xl', null, 'xxxxl']}
      sx={{ alignItems: [null, null, 'center'] }}
      columns={[null, null, null, '2fr 3fr']}
      gap={['l', null, 'xxl']}
    >
      <Box sx={{ order: [2, null, null, 1] }}>
        <Box
          sx={{
            marginBottom: 'l',
            maxWidth: [200, null, 240, 300],
            marginX: 'auto',
            '& svg': {
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <LogoStacked sx={{ display: 'block', marginX: 'auto' }} />
        </Box>

        <Box
          sx={{
            borderTop: 'solid 1px',
            borderBottom: 'solid 1px',
            borderColor: 'black.10',
            paddingY: 's',
            marginBottom: 'm',
          }}
        >
          <Text sx={{ textAlign: 'center' }}>
            Sun - Thurs · 10:00 AM - 10:00 PM
            <br />
            (Food served 12:00 PM - 8:30 PM)
            <br />
            Fri & Sat · 10:00 AM - 11:00 PM
            <br />
            (Food served 12:00 PM - 9:00 PM)
          </Text>
        </Box>
        <Box
          sx={{
            display: [null, null, 'flex'],
            marginBottom: 'l',
            justifyContent: 'center',
          }}
        >
          <Button as={Link} to="/accommodation" mr="m" mb={['s', null, 0]}>
            Book our rooms
          </Button>
          <Button as={Link} variant="secondary" to="/food-and-drink">
            View our menus
          </Button>
        </Box>

        <CallToBook sx={{ justifyContent: 'center' }}>
          (for groups of 6 or more)
        </CallToBook>
      </Box>

      <Box sx={{ order: [1, null, null, 2], position: 'relative' }}>
        <Box
          poster="/images/video-cover.jpg"
          variant="boxes.media"
          as="video"
          src="/hero.mp4"
          autoPlay
          loop
          muted
          playsInline
          sx={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',

            boxShadow: '0px 5px 20px rgba(0,0,0,0.18)',
            borderRadius: 4,
            maxWidth: '100%',
          }}
        />
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: [12, null, 24],
            gap: [12, null, 24],
            flexDirection: ['column', null, 'row'],
          }}
        >
          <p sx={{ margin: 0, fontSize: [12, null, 14], color: '#666666' }}>
            Enjoy a live view of the famous Ribblehead Viaduct brought to you by
            The Station Inn and Railcam UK. Ideal for viewing trains or the
            weather.
          </p>
          <div style={{ flexShrink: 0 }}>
            <Button as={Link} variant="secondary" to="/webcam">
              View live webcam
            </Button>
          </div>
        </div>
      </Box>
    </Grid>
  );
}

function Index() {
  return (
    <>
      <SEO />
      <Box>
        <Box
          sx={{
            display: 'flex',
            bg: 'orange.dark',
            color: 'white.100',
            py: 's',
            px: 'm',
            textAlign: 'center',
            textDecoration: 'none',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <PawIcon sx={{ display: 'block' }} width={24} height={21} />
          Dogs are welcome at the Inn
        </Box>

        <Box px={['m', null, 'l']}>
          <HomeHero />
        </Box>

        <AttractionMap showInfo />

        <LiveEvents />

        <StayTheNight />

        <Gander />
      </Box>
    </>
  );
}

export default Index;
